import { RefObject, useEffect } from 'react';

import { TOASTIFY_CLASS } from './constants';
import { clickedOnElement } from './utils';

const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: Function, classesToSkip: string[] = []): void => {
  useEffect(() => {
    const handelOutsideClick = (event: MouseEvent): void => {
      const elements = [...classesToSkip, TOASTIFY_CLASS];
      const skipHandle = elements.some((elementToSkip) => clickedOnElement(event, elementToSkip));
      if (!skipHandle && ref.current && !ref.current.contains(event.target as HTMLElement)) {
        handler();
      }
    };

    document.addEventListener('mousedown', handelOutsideClick, true);

    return (): void => {
      document.removeEventListener('mousedown', handelOutsideClick, true);
    };
  }, [ref, handler, classesToSkip]);
};

export default useOnClickOutside;
