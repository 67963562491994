import pako from 'pako';

import type { Permission, RoleV4, RoleV4Permission, UserInfo, UserRoleV4 } from '../types';

/**
 * Converts the given permission list from RoleV4 format to the format used by the User class
 * @param roleV4Permissions List of permissions in RoleV4 format
 * @returns List of permissions in User class format
 */
function roleV4ToUser(roleV4Permissions?: (RoleV4Permission | null)[] | null): Permission[] {
  if (!roleV4Permissions?.length) return [];

  return roleV4Permissions
    .filter((permission): permission is RoleV4Permission => !!permission)
    .map<Permission>((permission) => ({
      name: permission.name,
      'service-namespace': permission.serviceNamespace,
    }));
}

/**
 * Extracts user roles from the given UserInfo object and appends role
 * permission definitions from the supplied RoleV4 list
 * @param UserInfo A UserInfo object
 * @param roleDefinitions List of RoleV4 Definitions
 * @returns UserRoleV4
 */
export function getUserRoleInfo({ userInfo }: UserInfo, roleDefinitions: RoleV4[]): UserRoleV4[] | null {
  if (!userInfo) return null;

  // Decompress user roles if needed
  const userRoles: UserRoleV4[] = userInfo.compression
    ? JSON.parse(pako.inflate(Buffer.from(userInfo.user_role_v4, 'base64'), { to: 'string' }))
    : JSON.parse(userInfo.user_role_v4);

  if (!userRoles?.length) return null;

  // Append role permission definitions to each of the user's roles
  userRoles.forEach((userRole) => {
    const definition = roleDefinitions.find((definition) => definition.name === userRole.role.name);
    userRole.role.permissions = roleV4ToUser(definition?.permissions);
  });

  return userRoles;
}
