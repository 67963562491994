import { spacers } from '@hulu/design-tokens';

import { ThemeBranch } from '../types';

const admanSpacers = {
  spacer5px: '5px',
  spacer6px: '6px',
  spacer10px: '10px',
  spacer14px: '14px',
  spacer19px: '19px',
  spacer26px: '26px',
  spacer39px: '39px',
};

export type MCSpacersTheme = ThemeBranch<{
  spacers: typeof spacers;
  admanSpacers: typeof admanSpacers;
}>;

export const DefaultTheme: MCSpacersTheme = {
  spacers,
  admanSpacers,
};
