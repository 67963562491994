import { BaseSyntheticEvent, FC, FocusEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { ReactComponent as CheckedIcon } from '../../assets/images/checkedOption.svg';
import { ReactComponent as DropDownIcon } from '../../assets/images/dropDownIcon.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside/useOnClickOutside';
import Loader, { LoaderSize, LoaderType } from '../Loader';
import {
  MultipleDropDownWrapper,
  StyledClearButton,
  StyledErrorMessage,
  StyledInputWrapper,
  StyledOption,
  StyledOptionWrapper,
  StyledWrapperOption,
} from './styled';

export type Option = { value: string; label: ReactNode | string };

export type MultipleSelectConfig = {
  searchLoading: boolean;
  handleSearch: (searchValue: string) => void;
  handleInfinityScroll: () => void;
  withoutSelectIcon: boolean;
  isClearable: boolean;
  getSelectedOptions: (values: Option[]) => void;
};

type Props = {
  options?: Option[];
  label: ReactNode | string;
  handleChangeList?: (idList: string[]) => void;
  initialSelected?: string[];
  initialSelectedOptions?: Option[];
  isSearchable?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  disabled?: boolean;
  dataTestId?: string;
  selectedValues?: string;
  errMsg?: string | boolean;
  defaultHeight?: number;
  openOnTop?: boolean;
  title?: string;
  CustomRow?: FC<{
    defaultChecked: boolean;
    label: ReactNode;
    value: string;
    onChange: (e: BaseSyntheticEvent) => void;
    style: string;
  }>;
  config?: Partial<MultipleSelectConfig>;
  onBlur?: FocusEventHandler;
  height?: number;
};

const MultipleDropDown = ({
  label,
  options,
  handleChangeList,
  initialSelected,
  initialSelectedOptions,
  disabled,
  dataTestId,
  CustomRow,
  isSearchable = true,
  isClearable = false,
  placeholder = 'Select',
  selectedValues,
  defaultHeight,
  errMsg,
  openOnTop,
  title,
  config,
  onBlur,
  height = 10,
}: Props): JSX.Element => {
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(initialSelectedOptions || []);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(true);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isFetchingMoreData, setIsFetchingMoreData] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrapperRef, () => setShowOptions(false));

  useEffect(() => {
    if (isFetchingMoreData && !config?.searchLoading) {
      setIsFetchingMoreData(false);
    }
  }, [config?.searchLoading, isFetchingMoreData]);

  useEffect(() => {
    setFilteredOptions(options || []);
  }, [options]);

  useEffect(() => {
    if (initialSelected) {
      setSelectedIds(initialSelected);
      setSelectedOptions((current) => [...current].filter(({ value }) => initialSelected.includes(value)));
    }
  }, [initialSelected]);

  useEffect(() => {
    if (!showOptions && !isSubmitted) {
      if (config?.getSelectedOptions) {
        config.getSelectedOptions(selectedOptions);
      } else {
        handleChangeList?.(selectedIds);
      }

      setIsSubmitted(true);
    }
  }, [handleChangeList, selectedOptions, showOptions, isSubmitted, config, selectedIds]);

  const handleFetchMoreData = (): void => {
    setIsFetchingMoreData(true);
    config?.handleInfinityScroll?.();
  };

  const handleShowOptions = (value: boolean) => (): void => {
    if (!disabled) {
      setShowOptions(value);
    }
  };

  const handleSelectChange = useCallback(
    ({ value, checked }): void => {
      setIsSubmitted(false);
      inputRef?.current?.focus();

      if (value === 'selectAll' && options) {
        const filtered = options.filter(({ value }) => value !== 'selectAll');
        setSelectedIds(checked ? filtered.map((el) => el.value) : []);
        setSelectedOptions(checked ? filtered : []);
        return;
      }

      if (checked) {
        const newValue = (options || []).find((option) => value === option.value);
        const result = newValue ? [...selectedOptions, newValue] : [...selectedOptions];

        setSelectedIds([...selectedIds, value]);
        setSelectedOptions(result);
      } else {
        setSelectedIds([...selectedIds].filter((item) => item !== value));
        setSelectedOptions([...selectedOptions].filter((option) => value !== option.value));
      }
    },
    [options, selectedIds, selectedOptions]
  );

  const handleSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
    const searchValue = target.value.toLowerCase();

    setSearchValue(searchValue);

    if (config?.handleSearch) {
      config.handleSearch(searchValue);
    } else if (isSearchable && options) {
      const filteredOptions = [...options].filter(
        ({ label }) => (label as string).toLowerCase().indexOf(searchValue) + 1
      );

      setFilteredOptions(filteredOptions);
    }
  };

  const handleClearSelections = useCallback((): void => {
    setIsSubmitted(false);
    setSelectedIds([]);
    setSelectedOptions([]);
  }, []);

  const handleClearSearchValue = (): void => {
    setSearchValue('');
    config?.handleSearch?.('');

    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  const Row = useCallback(
    ({ index, style }): JSX.Element => {
      const value = filteredOptions?.[index]?.value || '';
      const label = filteredOptions?.[index]?.label || '';

      const selectedCurrentValue = selectedIds.includes(value);
      const selectedPrevValue = selectedIds.includes(filteredOptions?.[index - 1]?.value || '');
      const selectedNextValue = selectedIds.includes(filteredOptions?.[index + 1]?.value || '');

      if (CustomRow && options) {
        return (
          <CustomRow
            defaultChecked={value !== 'selectAll' ? selectedCurrentValue : options.length - 1 === selectedIds.length}
            label={label}
            value={value}
            onChange={handleSelectChange}
            style={style}
          />
        );
      }

      return (
        <StyledWrapperOption style={style} key={value}>
          {selectedCurrentValue && !config?.withoutSelectIcon && <CheckedIcon />}
          <StyledOption
            data-testid="multipleDropDownLabel"
            withoutSelectIcon={config?.withoutSelectIcon}
            $checkedValues={[selectedPrevValue, selectedCurrentValue, selectedNextValue]}
          >
            <div data-testid="multipleDropDownSelect">
              <input
                data-testid={dataTestId}
                type="checkbox"
                value={value}
                defaultChecked={selectedCurrentValue}
                onChange={(event): void => {
                  handleSelectChange(event.target);
                }}
                onBlur={onBlur}
                hidden
              />
            </div>
            {label}
          </StyledOption>
        </StyledWrapperOption>
      );
    },
    [
      CustomRow,
      config?.withoutSelectIcon,
      dataTestId,
      filteredOptions,
      handleSelectChange,
      onBlur,
      options,
      selectedIds,
    ]
  );

  const filteredCount: number = filteredOptions?.length || 0;

  return (
    <MultipleDropDownWrapper ref={wrapperRef} data-testid="multipleDropDown">
      {label && <p>{label}</p>}
      <StyledInputWrapper $focused={showOptions} $errMsg={Boolean(errMsg)}>
        <div data-testid="multipleDropDownInput">
          <input
            data-testid={dataTestId}
            id={dataTestId}
            ref={inputRef}
            type="text"
            value={isSearchable ? undefined : selectedValues}
            onFocus={handleShowOptions(true)}
            onChange={handleSearchChange}
            placeholder={placeholder}
            title={title}
            disabled={disabled || (!showOptions && config?.searchLoading)}
            autoComplete="off"
            onBlur={onBlur}
          />
          {errMsg && !showOptions && <StyledErrorMessage>{errMsg}</StyledErrorMessage>}
        </div>
        {config?.searchLoading && <Loader size={LoaderSize.medium} type={LoaderType.transparent} />}
        {config?.isClearable && !config?.searchLoading && searchValue && (
          <StyledClearButton data-testid="clearSearchValueButton" onClick={handleClearSearchValue} />
        )}
        {isClearable && selectedIds.length ? (
          <StyledClearButton data-testid="clearSelectionsButton" onClick={handleClearSelections} />
        ) : (
          ''
        )}
        <DropDownIcon data-testid="multipleDropDownInputButton" onClick={handleShowOptions(!showOptions)} />
      </StyledInputWrapper>
      {showOptions && (isFetchingMoreData || !config?.searchLoading) && (
        <StyledOptionWrapper $openOnTop={openOnTop} data-testid="multipleDropDownFixedSizeList">
          {!filteredCount ? (
            <StyledOption>No data</StyledOption>
          ) : (
            <InfiniteLoader
              isItemLoaded={(index): boolean => index < filteredCount - 1}
              itemCount={filteredCount}
              loadMoreItems={handleFetchMoreData}
              threshold={0}
            >
              {({ onItemsRendered, ref }): JSX.Element => (
                <FixedSizeList
                  height={defaultHeight ? defaultHeight : filteredCount > height ? height * 50 : filteredCount * 50}
                  width="100%"
                  itemSize={50}
                  itemCount={filteredCount}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {Row}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </StyledOptionWrapper>
      )}
    </MultipleDropDownWrapper>
  );
};

export default MultipleDropDown;
