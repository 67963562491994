import type { Permission, RequiredRole, UserRoleV4 } from '../types';

function roleHasPermissions(rolePermissions?: Permission[], requiredPermissions?: Permission[]): boolean {
  if (!rolePermissions?.length || !requiredPermissions?.length) return false;

  return requiredPermissions.some((requiredPermission) =>
    rolePermissions.some(
      (rolePermission) =>
        rolePermission.name === requiredPermission.name &&
        rolePermission['service-namespace'] === requiredPermission['service-namespace']
    )
  );
}

export function hasRequiredPermission(userRoles: UserRoleV4[] | null, requiredRole: RequiredRole): boolean {
  if (!userRoles?.length) return false;

  return userRoles.some((userRole) =>
    requiredRole.name.some(
      (roleName) =>
        roleName === userRole.role.name && roleHasPermissions(userRole.role.permissions, requiredRole.permissions)
    )
  );
}
