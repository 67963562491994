import { Auth } from 'aws-amplify';
import { CookieOptions } from 'express';
import Cookie from 'universal-cookie';

export class HelpSiteCookieHelper {
  static helpSiteIdTokenCookieName: string = 'apex__dcmIdToken';
  static helpSiteRefreshTokenCookieName: string = 'apex__hamToken';

  static setHelpSiteCookieWithIdAndRefresh = async (
    refreshToken: string | undefined,
    idToken: string | undefined
  ): Promise<void> => {
    try {
      const cookies = new Cookie();

      console.log('Setting help center cookie to: ' + HelpSiteCookieHelper.getCookieOptions().domain);

      if (refreshToken) {
        cookies.set(
          HelpSiteCookieHelper.helpSiteRefreshTokenCookieName,
          refreshToken,
          HelpSiteCookieHelper.getCookieOptions()
        );
      }

      if (idToken) {
        cookies.set(HelpSiteCookieHelper.helpSiteIdTokenCookieName, idToken, HelpSiteCookieHelper.getCookieOptions());
      }
    } catch (e) {
      console.log('Unable to set help site cookie.');
    }
  };

  static setHelpSiteCookieForNonAdmin = async (): Promise<void> => {
    try {
      const session = await Auth.currentSession();

      const refreshToken = session.getRefreshToken().getToken();
      const idToken = session.getIdToken().getJwtToken();

      await this.setHelpSiteCookieWithIdAndRefresh(refreshToken, idToken);
    } catch (e) {
      console.log('Unable to set non-admin help site cookie.');
    }
  };

  static unsetHelpSiteCookie = (): void => {
    const cookies = new Cookie();
    console.log('Removing help center cookies.');
    cookies.remove(HelpSiteCookieHelper.helpSiteIdTokenCookieName, HelpSiteCookieHelper.getCookieOptions());
    cookies.remove(HelpSiteCookieHelper.helpSiteRefreshTokenCookieName, HelpSiteCookieHelper.getCookieOptions());
  };

  private static getCookieOptions = (): CookieOptions => {
    const domain: string =
      window.location.hostname == 'localhost' ? window.location.hostname : '.' + window.location.host;

    return {
      domain: domain,
      secure: true,
      path: '/',
    };
  };
}
