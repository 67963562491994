import { BrowserRouter as Router } from '@hulu/react-router-dom';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import App from './App';
import GlobalErrorFallback from './components/ErrorBoundary/GlobalErrorFallback';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import logError from './utils/logErrorBoundary';

const antdTheme = { token: { fontFamily: 'Graphik' } };

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    height: 100vh;
    box-sizing: border-box;
    font-family: Graphik,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .TrekDropDown-popUp {
      max-height: 230px;
    }

    .TrekDropDownItem-root{
      border-radius: 8px;
      color: ${({ theme }): string => theme.admanColors!.gray.light.fg[1]};
      &:after {
        border-bottom: none;
      }
      &:hover{
        background-color: ${({ theme }): string => theme.admanColors!.gray.light.bg0[1]};
      }
    }

    .TrekDropDownItem-selected {
      background-color: ${({ theme }): string => theme.admanColors!.blue.light.bg[0]};
      color: ${({ theme }): string => theme.admanColors!.gray.light.fg[0]};

      &:hover{
        background-color: ${({ theme }): string => theme.admanColors!.blue.light.bg[1]};
      }
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  div .TrekDropDown-popUpContainer {
    z-index: 1301;
  }
`;

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ErrorBoundary FallbackComponent={GlobalErrorFallback} onError={logError}>
      <Router>
        <ConfigProvider theme={antdTheme}>
          <GlobalStyle />
          <App />
        </ConfigProvider>
      </Router>
    </ErrorBoundary>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
