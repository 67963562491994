import { ThemeBranch } from '../types';

type sideBarSizesType = {
  footerHeight: string;
  titleHeight: string;
};

const sideBarSizes = {
  footerHeight: '80px',
  titleHeight: '30px',
};

export type SideBarSizesTheme = ThemeBranch<{
  sideBarSizes: sideBarSizesType;
}>;

export const DefaultTheme: SideBarSizesTheme = {
  sideBarSizes,
};
