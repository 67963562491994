import { Typography } from '@hulu/react-style-components';
import CommunityLoader from '@hulu-react-style-components/community-loader';
import { IconChevronDown } from '@hulu-react-style-components/icons';
import styled, { CSSObject, DefaultTheme } from 'styled-components';

import { ReactComponent as DropDownIcon } from '../assets/images/dropDownIcon.svg';
import { DateRangeWrapper } from './DateRangeFilter/styled';
import MultipleDropDown from './MultipleDropDown';
import { StyledWrapperOption } from './MultipleDropDown/styled';

export const StyledVerticalFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledLoader = styled(CommunityLoader)`
  margin: ${({ theme }): string => theme.spacers!.spacer11} auto;
  flex-shrink: 0;
`;

export const ActiveAccordionIcon = styled(IconChevronDown)<{ $isActive: boolean }>`
  margin-left: auto;
  transition: transform 0.4s ease;
  transform: ${({ $isActive }): string => ($isActive ? 'rotate(-180deg)' : 'none')};
`;
export const StyledTitle = styled(Typography).attrs({ variant: 'body2' })`
  color: ${({ theme }): string => theme.admanColors!.blue.solid};
  font-weight: 500;
  max-width: 800px;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledNotClickableTitle = styled(Typography).attrs({ variant: 'body2' })`
  color: ${({ theme }): string => theme.admanColors!.gray.light.fg[0]};
  font-weight: 400;
  max-width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`;

export const StyledBlock = styled.div`
  border-radius: ${({ theme }): string => `${theme.spacers!.spacer04}`};
  background: white;
  width: 100%;
  box-shadow: 0 2px 12px -8px rgba(19, 32, 57, 0.4);
  padding: ${({ theme }): string => `${theme.spacers!.spacer05}`} ${({ theme }): string => `${theme.spacers!.spacer06}`};
  min-height: 122px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledLinkOrg = styled.div<{ $isNotClickable?: boolean }>`
  font-weight: 500;
  line-height: 100%;
  color: ${({ theme, $isNotClickable }): string =>
    $isNotClickable ? theme.admanColors!.gray.light.fg[0] : theme.admanColors!.blue.solid};
  font-size: 14px;

  display: block;
  white-space: nowrap;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    cursor: ${({ $isNotClickable }): string => ($isNotClickable ? 'text' : 'pointer')};
  }
`;

export const zeroStateButtonStyles = (theme: DefaultTheme): CSSObject => ({
  background: theme.admanColors?.gray.light.fg[0],
  color: theme.admanColors?.gray.light.bg1[0],
  ':hover': {
    opacity: '0.8',
    background: theme.admanColors?.gray.dark.bg1[1],
  },
  ':disabled': {
    background: theme.admanColors?.gray.light.bg0[6],
  },
});

export const NoDataWrap = styled.div`
  margin-top: ${({ theme: { spacers } }): string => spacers!.spacer06};
  width: 100%;
`;

export const StyledTitleDetails = styled(Typography).attrs({ variant: 'badge' })`
  display: block;
  color: ${({ theme }): string => theme.admanColors!.gray.light.fg[2]};
  margin-bottom: ${({ theme }): string => theme.spacers!.spacer03};
  font-weight: 600;
`;

export const StyledDateRangeWrapper = styled(DateRangeWrapper)`
  flex-direction: row;

  > div {
    max-width: 49%;

    input {
      min-width: 200px;
    }
  }
`;

export const StyledLabel = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: none;
  color: ${({ theme }): string => theme.admanColors!.gray.light.fg[2]};
  letter-spacing: 0px;
  padding-bottom: ${({ theme }): string => theme.spacers!.spacer02};
`;

export const StyledLineItemsBlock = styled(StyledBlock)`
  > div > div:nth-child(2) {
    max-width: 450px;
  }
  margin-bottom: ${({ theme }): string => theme.spacers!.spacer03};
`;

export const StyledMultipleDropDown = styled(MultipleDropDown)`
  input {
    text-overflow: ellipsis;
  }
`;

export const FieldWithLoader = styled.div`
  position: relative;
`;

export const FieldLoader = styled(StyledLoader)`
  width: 20px;
  height: 20px;
  border-width: 2px;
  position: absolute;
  left: 10px;
  top: 12px;
  margin: 0;
`;

export const StyledBrokeLine = styled.div`
  height: 1px;
  width: 100%;
  margin-top: ${({ theme }): string => theme.spacers!.spacer06};
  background-color: ${({ theme }): string => theme.admanColors!.gray.light.bg0[3]};
`;

export const StyledReportBrokeLine = styled(StyledBrokeLine)`
  margin-bottom: ${({ theme }): string => theme.spacers!.spacer06};
`;

export const StyledReportBlockWithBottomMarginForAllLI = styled.div<{ $withoutMargin?: boolean }>`
  & > div:last-child {
    margin-bottom: ${({ theme, $withoutMargin }): string => (!!$withoutMargin ? '0px' : theme.spacers!.spacer07)};
  }

  .menuList > div:first-child {
    border-bottom: 1px solid ${({ theme }): string => theme.admanColors!.gray.dark.fg[2]};
  }
`;

export const StyledReportBlockWithBottomMargin = styled.div<{ $withoutMargin?: boolean }>`
  & > div:last-child {
    margin-bottom: ${({ theme, $withoutMargin }): string => (!!$withoutMargin ? '0px' : theme.spacers!.spacer07)};
  }
`;

export const StyledContainerForTitle = styled.p<{ $maxWidth?: string; $maxHeight?: string }>`
  max-width: ${({ $maxWidth }): string => $maxWidth || '300px'};
  max-height: ${({ $maxHeight }): string => $maxHeight || '120px'};
  line-break: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledWeekdayBlock = styled.div`
  max-width: 180px;
  padding: ${({ theme }): string => theme.spacers!.spacer02} 0 ${({ theme }): string => theme.spacers!.spacer02}
    ${({ theme }): string => theme.spacers!.spacer05};

  .valueContainer {
    flex-wrap: nowrap;
    > div:not(:first-child, :nth-child(2)) {
      display: none;
    }
    &:first-child {
    }
  }

  .option {
    padding: ${({ theme }): string => theme.spacers!.spacer03} 0;
  }
`;

export const StyledWrapperOptionDropdown = styled(StyledWrapperOption)<{ $selectAll?: boolean }>`
  max-width: 100%;

  label {
    min-width: 16px;
    padding: 0 ${({ theme }): string => theme.spacers!.spacer02};
  }

  border-bottom: ${({ $selectAll, theme }): string =>
    $selectAll ? `1px solid  ${theme.admanColors!.gray.light.fg[2]}` : ''};
  border-radius: inherit;
`;

export const StyledCheckboxLabel = styled.span`
  text-transform: none;
  font-weight: normal;
  max-width: 350px;
`;

export const StyledGrayTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }): string => theme.admanColors!.gray.light.fg[2]};
`;

export const FullScreenHeightBlock = styled.div`
  height: 100vh;
`;

export const ActionButtons = styled.div<{ $left?: string; $right?: string }>`
  position: absolute;
  width: 182px;
  height: auto;
  left: ${({ $left }): string => $left || 'unset'};
  right: ${({ $right }): string => $right || 'unset'};
  top: 49px;
  background: ${({ theme }): string => theme.admanColors!.gray.light.bg0[0]};
  border: 1px solid ${({ theme }): string => theme.admanColors!.gray.light.bg0[3]};
  box-shadow: 0 4px 16px -8px rgba(19, 32, 57, 0.4);
  border-radius: 12px;
  padding: ${({ theme }): string => theme.admanSpacers!.spacer5px};
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 ${({ theme }): string => theme.spacers!.spacer03};
    width: 100%;
    height: 40px;
    border-radius: 7px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.01em;

    &.transparent {
      align-items: center;
    }

    &:hover {
      background: ${({ theme }): string => theme.admanColors!.gray.light.bg0[1]};
    }
  }
`;

export const StyledAction = styled.div<{ disabled?: boolean }>`
  color: ${({ theme, disabled }): string => (disabled ? theme.admanColors!.gray.light.fg[4] : 'inherit')};

  &:hover {
    cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const StyledDropDownIcon = styled(DropDownIcon)<{ $activated: boolean }>`
  transition: 0.2s;
  transform: rotate(${({ $activated }): number => ($activated ? 180 : 0)}deg);
`;

export const CollapsedText = styled(Typography).attrs({ variant: 'body2' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 350px;
`;

export const StyledIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0;

  span {
    padding: ${({ theme }): string => theme.spacers!.spacer02};
  }

  .deactivate-icon-wrapper span {
    padding: 0;
  }
`;
