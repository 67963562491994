import styled from 'styled-components';

import loader from '../../assets/images/loading.svg';

export const Wrap = styled.div`
  width: 100%;
  // size of table
  max-width: 1384px;
  text-align: center;
  background-color: ${({ theme }): string => theme.colors!.colorWhite};
  display: flex;
  justify-content: center;

  &.transparent {
    background-color: transparent;
  }

  &.cover {
    background-color: #ffffff96;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LoaderWrap = styled.span`
  width: 35px;
  height: 35px;
  display: inline-block;
  background-image: url('${loader}');
  background-size: contain;
  animation: rotate 1s linear infinite;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  &.big {
    width: 40px;
    height: 40px;
  }

  &.medium {
    width: 24px;
    height: 24px;
  }

  &.small {
    width: 14px;
    height: 14px;
  }
`;
