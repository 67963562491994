import {
  BroadcastStatus,
  InvoicePaymentStatuses,
  LineItemStatus,
  PacingStatus,
  ReportStatus,
  ReportTemplateSystemName,
  TransactionStatuses,
} from '../apis/graphql/types';
import { Option } from '../components/types';

export enum StatusColor {
  GREEN,
  BLUE,
  GRAY,
  RED,
  YELLOW,
}

export const LINE_ITEM_STATUS_MAP = {
  APPROVED: StatusColor.GREEN,
  LIVE: StatusColor.GREEN,
  SUBMITTED: StatusColor.GREEN,
  NEEDS_AD: StatusColor.YELLOW,
  PAUSED: StatusColor.YELLOW,
  UNDER_REVIEW: StatusColor.BLUE,
  READY: StatusColor.BLUE,
  PROCESSING: StatusColor.BLUE,
  DRAFT: StatusColor.GRAY,
  COMPLETE: StatusColor.GRAY,
  PENDING_APPROVAL: StatusColor.GRAY,
  REJECTED: StatusColor.RED,
  SUSPENDED: StatusColor.RED,
  CANCELLED: StatusColor.RED,
  DELIVERED: StatusColor.GREEN,
  UNDERDELIVERED: StatusColor.GRAY,
};

type PacingStatusKeys = keyof typeof PacingStatus;
type PacingStatusValues = (typeof PacingStatus)[PacingStatusKeys];

export const PACING_STATUS_MAP: Record<PacingStatusValues, StatusColor> = {
  ON_PACING: StatusColor.GREEN,
  UNDER_PACING: StatusColor.RED,
  OVER_PACING: StatusColor.YELLOW,
};

export enum USER_STATUSES {
  invited = 'INVITED',
  unknown = 'UNKNOWN',
  active = 'ACTIVE',
  deactivated = 'DEACTIVATED',
}

export type USER_STATUS = 'ACTIVE' | 'INVITED' | 'DEACTIVATED';

export const USER_STATUS_MAP = {
  ACTIVE: StatusColor.GREEN,
  INVITED: StatusColor.GRAY,
  DEACTIVATED: StatusColor.RED,
};

export const PACE_STATUS_OPTIONS: Option[] = [
  { value: 'ON_PACING', label: 'On Pace' },
  { value: 'OVER_PACING', label: 'Over Pacing' },
  { value: 'UNDER_PACING', label: 'Under Pacing' },
];

export const LINE_ITEM_STATUS_OPTIONS: Option[] = [
  { value: LineItemStatus.Cancelled, label: 'Cancelled' },
  { value: LineItemStatus.Complete, label: 'Complete' },
  { value: LineItemStatus.Draft, label: 'Draft' },
  { value: LineItemStatus.Live, label: 'Live' },
  { value: LineItemStatus.Paused, label: 'Paused' },
  { value: LineItemStatus.PendingApproval, label: 'Pending Approval' },
  { value: LineItemStatus.Ready, label: 'Ready' },
  { value: LineItemStatus.Suspended, label: 'Suspended' },
];
export const AD_ACCOUNT_STATUS_MAP = {
  APPROVED: StatusColor.GREEN,
  SUBMITTED: StatusColor.GREEN,
  PUBLISHED: StatusColor.GREEN,
  UNDER_REVIEW: StatusColor.BLUE,
  REJECTED: StatusColor.RED,
  UNKNOWN: StatusColor.YELLOW,
  ACTIVE: StatusColor.GREEN,
  CLOSED: StatusColor.RED,
  DISABLED: StatusColor.GRAY,
  INACTIVE: StatusColor.GRAY,
  IN_GRACE_PERIOD: StatusColor.BLUE,
};

export const BRAND_STATUS_MAP = {
  ACTIVE: StatusColor.GREEN,
  INACTIVE: StatusColor.GRAY,
  IN_REVIEW: StatusColor.BLUE,
  APPROVED: StatusColor.GREEN,
  REJECTED: StatusColor.RED,
};

export const ADMIN_PANEL_USER_STATUS = {
  ACTIVE: USER_STATUSES.active,
  DEACTIVATED: USER_STATUSES.deactivated,
  INVITED: USER_STATUSES.invited,
  EXPIRED: 'EXPIRED',
};

export const USER_STATUSES_OPTIONS: Option[] = [
  { value: ADMIN_PANEL_USER_STATUS.ACTIVE, label: 'Active' },
  { value: ADMIN_PANEL_USER_STATUS.DEACTIVATED, label: 'Deactivated' },
  { value: ADMIN_PANEL_USER_STATUS.INVITED, label: 'Invited' },
  { value: ADMIN_PANEL_USER_STATUS.EXPIRED, label: 'Expired' },
];

export const TRANSACTION_STATUS_MAP = {
  [TransactionStatuses.Success]: StatusColor.GREEN,
  [TransactionStatuses.Initiated]: StatusColor.GRAY,
  [TransactionStatuses.CustomerFailed]: StatusColor.RED,
  [TransactionStatuses.SystemFailed]: StatusColor.RED,
  [TransactionStatuses.Refunded]: StatusColor.BLUE,
  [TransactionStatuses.Chargeback]: StatusColor.YELLOW,
  [TransactionStatuses.ChargebackReversed]: StatusColor.BLUE,
  [TransactionStatuses.RefundFailed]: StatusColor.RED,
  [TransactionStatuses.RefundedReversed]: StatusColor.BLUE,
  [TransactionStatuses.RefundInitiated]: StatusColor.BLUE,
};

export const transactionStatuses = {
  [TransactionStatuses.Success]: 'SUCCESS',
  [TransactionStatuses.Initiated]: 'INITIATED',
  [TransactionStatuses.SystemFailed]: 'FAILED',
  [TransactionStatuses.CustomerFailed]: 'FAILED',
  [TransactionStatuses.Refunded]: 'REFUNDED',
  [TransactionStatuses.Chargeback]: 'CHARGEBACK',
  [TransactionStatuses.ChargebackReversed]: 'CHARGEBACK REVERSED',
  [TransactionStatuses.RefundFailed]: 'REFUND FAILED',
  [TransactionStatuses.RefundedReversed]: 'REFUNDED REVERSED',
  [TransactionStatuses.RefundInitiated]: 'REFUND INITIATED',
};

export const INVOICE_STATUS_MAP = {
  [InvoicePaymentStatuses.Success]: StatusColor.GREEN,
  [InvoicePaymentStatuses.Failed]: StatusColor.RED,
  [InvoicePaymentStatuses.Pending]: StatusColor.GRAY,
  [InvoicePaymentStatuses.Processing]: StatusColor.BLUE,
  [InvoicePaymentStatuses.Void]: StatusColor.RED,
  [InvoicePaymentStatuses.Hold]: StatusColor.YELLOW,
  [InvoicePaymentStatuses.Paused]: StatusColor.YELLOW,
  [InvoicePaymentStatuses.Overdue]: StatusColor.RED,
  [InvoicePaymentStatuses.PartiallyFailed]: StatusColor.RED,
  [InvoicePaymentStatuses.RevisedPending]: StatusColor.GRAY,
  [InvoicePaymentStatuses.PartialPayment]: StatusColor.YELLOW,
  [InvoicePaymentStatuses.RefundFailed]: StatusColor.RED,
};

export const invoiceStatuses = {
  [InvoicePaymentStatuses.Success]: 'PAID',
  [InvoicePaymentStatuses.Failed]: 'UNPAID',
  [InvoicePaymentStatuses.Pending]: 'PENDING',
  [InvoicePaymentStatuses.Processing]: 'PROCESSING',
  [InvoicePaymentStatuses.Void]: 'VOID',
  [InvoicePaymentStatuses.Hold]: 'HOLD',
  [InvoicePaymentStatuses.Paused]: 'PAUSED',
  [InvoicePaymentStatuses.Overdue]: 'OVERDUE',
  [InvoicePaymentStatuses.PartiallyFailed]: 'PARTIALLY FAILED',
  [InvoicePaymentStatuses.RevisedPending]: 'REVISED PENDING',
  [InvoicePaymentStatuses.PartialPayment]: 'PARTIAL PAYMENT',
  [InvoicePaymentStatuses.RefundFailed]: 'REFUND FAILED',
};
export const ReportStatusVariables = {
  Complete: 'COMPLETE',
  Ready: 'READY',
  Processing: 'PROCESSING',
  Scheduled: 'SCHEDULED',
  Stopped: 'STOPPED',
  Failed: 'FAILED',
} as const;

export const REPORT_STATUS_OPTIONS: Option[] = [
  { value: ReportStatus.Ready, label: 'Ready' },
  { value: ReportStatus.Pending, label: 'Processing' },
  { value: ReportStatus.Scheduled, label: 'Scheduled' },
  { value: ReportStatus.Stopped, label: 'Stopped' },
  { value: ReportStatus.Complete, label: 'Complete' },
  { value: ReportStatus.Failed, label: 'Failed' },
];

export const REPORT_TYPE_OPTIONS: Option[] = [
  { value: ReportTemplateSystemName.AllLineItems, label: 'All Campaigns' },
  { value: ReportTemplateSystemName.DetailedData, label: 'Detailed Data' },
  { value: ReportTemplateSystemName.LineItemDrafts, label: 'Line Item Drafts' },
  { value: ReportTemplateSystemName.LineItemsWithoutAds, label: 'Line Item without Ads' },
];

export const BROADCASTS_STATUS_MAP = {
  [BroadcastStatus.Complete]: StatusColor.GRAY,
  [BroadcastStatus.Draft]: StatusColor.GRAY,
  [BroadcastStatus.Failed]: StatusColor.RED,
  [BroadcastStatus.Processing]: StatusColor.BLUE,
  [BroadcastStatus.Scheduled]: StatusColor.GRAY,
  [BroadcastStatus.Stopped]: StatusColor.RED,
  [BroadcastStatus.Sent]: StatusColor.GRAY,
};
