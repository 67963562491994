export const TOOLTIPS = {
  brand: {
    title: 'Brand',
    text: [
      `The Brand name uniquely identifies the company product or service being advertised. It provides for a consistent campaign and Ad Brand.`,
    ],
  },
  adAccounts: {
    title: 'Ad Accounts',
    text: [
      'The Ad Account provides a single place to manage a set of campaigns, Ads, reports, and billing and payments for the Brand.',
      'Typically, one Ad Account is set up per Brand, but multiple Ad Accounts can be set up for the Brand, each with a separate set of campaigns, Ads, reports, and billing and payment information.',
    ],
  },
  industry: {
    title: 'Industry*',
    text: 'The industry is a grouping of businesses that provide a closely related set of products or services. An industry that best represents the Brand is associated with the Brand. It determines if Ads fall into Special or Political Ads Categories.',
  },
  threshold: {
    text: 'This is the cost for delivered impressions for campaigns in the Ad Account that must be reached before your credit card is charged. The amount charged will vary based on impression actuals delivered toward your threshold until it is met or exceeded.',
  },
  userAssignments: {
    'Organization Member':
      'Organization Members can view organization information. They can be assigned an Ad Account role for access to Ad Account information.',

    'Organization Admin':
      'Organization Admins can manage all organization information and have full control over all Ad Accounts. They can invite users, view user information, and manage Brands, Ad Accounts and members, business information and payment.',

    'Creative Manager':
      'Creative Managers can view organization information and have full control over all creatives in the creative library for their organization.',

    'Ad Account Admin':
      'Ad Account Admins can invite users and manage Ad Accounts and members in assigned Ad Accounts. They can manage campaigns, Ads, and reports in assigned Ad Accounts. They can also view organization information, organization Admin users, and Ad Account Admin users and billing and payment information in assigned Ad Accounts.',

    'Ad Account Analyst':
      'Ad Account Analysts can view campaigns and Ads and manage reports in assigned Ad Accounts. They can also view organization information, organization Admin users, and Ad Accounts and members and billing and payment information in assigned Ad Accounts.',

    Analyst:
      'Ad Account Analysts can view campaigns and Ads and manage reports in assigned Ad Accounts. They can also view organization information, organization Admin users, and Ad Accounts and members and billing and payment information in assigned Ad Accounts.',

    'Marketer Analyst':
      'Marketer Analysts are reporting portal users. They can view campaigns and Ads and manage reports on all Ad Accounts for the organization. They can also view organization Admin users and can view Ad Accounts and members and billing and payment information on all Ad Accounts.',

    Buyer:
      'Ad Account Buyers can manage campaigns, Ads, and reports in assigned Ad Accounts. They can also view organization information, organization Admin users, and Ad Accounts and members and billing and payment information in assigned Ad Accounts.',
  } as Record<string, string>,
  businessAddress: {
    title: 'Business Address*',
    text: 'This is the business address of your client advertiser, not your agency business address. We use this to identify the Brand of your client advertiser and to help with Brand frequency capping for a better user experience.',
  },
  tags: {
    title: 'tags',
    text: [
      '3rd party tags are uploaded and configured with values Hulu uses to return Ad serving metrics.',
      'When the Ad goes live and is viewed at the Ad viewing position, the Ad server will collect and send actuals (such as impression and device ID data) to the 3rd party for analysis and measurement.',
    ],
  },
  billingContact: {
    title: 'Billing Contact*',
    text: 'Select a billing contact who is responsible for paying for Ads in the Ad Account for the Brand.\n\nThe billing contact must be a Hulu Ad Manager user within your organization. You can use the same or different billing contacts in each of your Ad Accounts.',
  },
  billBy: {
    title: 'Bill by',
    text: 'The Campaign option generates a separate invoice and transaction for each campaign with billable impressions in the Ad Account.\n\nThe Ad Account option generates a single invoice and transaction for campaigns with billable impressions aggregated by Ad Account.',
  },
  billableAdServer: {
    text: 'Billable Ad Server*',
    description:
      '1P collects and validates impressions on our 1st Party Ad Manager server.\n\nNielsen is based on Nielsen Digital Ad Ratings (DAR) that considers co-viewing in which members of the same household viewing the Ad at the same time on the connected device are counted as individual impressions.\n\n3P collects and validates impressions with the 3rd Party billing vendor.',
  },
};
