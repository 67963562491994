import envConfig from '@hulu/env-config';

import { COGNITO_FALLBACK_HOST } from '../interfaces';

const COGNITO_HOST = envConfig.REACT_APP_COGNITO_HOST || COGNITO_FALLBACK_HOST;
const CLIENT_ID = envConfig.REACT_APP_COGNITO_ADMIN_CLIENT_ID;

export const getCognitoURL = (): string => {
  // authorization endpoint required parameters
  const RESPONSE_TYPE = 'code';
  const SCOPE = ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'].join(' ');

  const REDIRECT_ENDPOINT = `${window.location.origin}/admin/oauth/callback`;
  const AUTHORIZE_ENDPOINT = `${COGNITO_HOST}/login`;

  return (
    `${AUTHORIZE_ENDPOINT}?redirect_uri=${REDIRECT_ENDPOINT}` +
    `&response_type=${RESPONSE_TYPE}` +
    `&client_id=${CLIENT_ID}` +
    `&scope=${SCOPE}`
  );
};
