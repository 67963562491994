import envConfig from '@hulu/env-config';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import qs from 'qs';

import type { UserTokensSnake } from '../types';

enum GrantType {
  AuthCode = 'authorization_code',
  Refresh = 'refresh_token',
}
type QueryOptions =
  | { grant_type: GrantType.AuthCode; code: string; redirect_uri: string }
  | { grant_type: GrantType.Refresh; refresh_token: string };

export const COGNITO_FALLBACK_HOST = 'https://ads-stg1-haapi.auth.us-west-2.amazoncognito.com';

const COGNITO_CLIENT_ID = envConfig.REACT_APP_COGNITO_ADMIN_CLIENT_ID;
const COGNITO_TOKEN_ENDPOINT: string = `${envConfig.REACT_APP_COGNITO_HOST || COGNITO_FALLBACK_HOST}/oauth2/token`;
const REDIRECT_ENDPOINT = `${window.location.origin}/admin/oauth/callback`;
const XSRF_TOKEN = '6eace47d-014f-4c79-9b5d-4e5fefc4ea3e';

export class Cognito {
  private static async sendQuery(
    config: QueryOptions,
    useXsrfToken?: boolean
  ): Promise<AxiosResponse<UserTokensSnake & { error?: string }>> {
    const headers: AxiosRequestConfig['headers'] = { 'Content-Type': 'application/x-www-form-urlencoded' };
    if (useXsrfToken) headers['Cookie'] = `XSRF-TOKEN=${XSRF_TOKEN}`;

    // Reference on how axios send data in 'application/x-www-form-urlencoded' format:
    // https://www.npmjs.com/package/axios#using-applicationx-www-form-urlencoded-format
    return await axios.post<UserTokensSnake & { error?: string }>(
      COGNITO_TOKEN_ENDPOINT,
      qs.stringify({ client_id: COGNITO_CLIENT_ID, ...config }),
      { headers }
    );
  }

  static async sendOauthCallback(
    code: string,
    useXsrfToken?: boolean
  ): Promise<AxiosResponse<UserTokensSnake & { error?: string }>> {
    return this.sendQuery(
      {
        grant_type: GrantType.AuthCode,
        redirect_uri: REDIRECT_ENDPOINT,
        code,
      },
      useXsrfToken
    );
  }

  static async sendTokenRefresh(
    token: string,
    useXsrfToken?: boolean
  ): Promise<AxiosResponse<UserTokensSnake & { error?: string }>> {
    return this.sendQuery(
      {
        grant_type: GrantType.Refresh,
        refresh_token: token,
      },
      useXsrfToken
    );
  }
}
