export const DEFAULT_ENTRIES_PER_PAGE = 10;

export const PAGINATION_OPTIONS_BASED = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
];

export const PAGINATION_OPTIONS = [{ value: '2', label: '2' }, { value: '5', label: '5' }, ...PAGINATION_OPTIONS_BASED];

export const PAGINATION_MAX_COUNT = 5;
