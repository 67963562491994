import styled from 'styled-components';

export const DateRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${({ theme }): string => theme.spacers!.spacer01};
  z-index: 16;

  input {
    min-width: 216px;
    border-radius: 4px;
    border: 1px solid ${({ theme }): string => theme.admanColors!.gray.light.bg0[3]};

    :hover {
      border: 2px solid ${({ theme }): string => theme.admanColors!.gray.light.bg0[5]};
    }
  }
`;
