import type { UserTokensCamel, UserTokensSnake } from '../types';
import { HelpSiteCookieHelper } from './HelpSiteCookieHelper';

export class LocalStorage {
  static setTokens = (data: UserTokensSnake): void => {
    if (data.access_token) localStorage.setItem('access_token', data.access_token);
    if (data.id_token) localStorage.setItem('id_token', data.id_token);
    if (data.refresh_token) localStorage.setItem('refresh_token', data.refresh_token);
    HelpSiteCookieHelper.setHelpSiteCookieWithIdAndRefresh(data.refresh_token, data.id_token).then((r) =>
      console.log('Set admin help site tokens.')
    );
  };

  static getTokens = (): UserTokensCamel => {
    const idToken = localStorage.getItem('id_token');
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    return { idToken, accessToken, refreshToken };
  };
  static clearTokens = (): void => {
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    HelpSiteCookieHelper.unsetHelpSiteCookie();
  };
}
