import { themeUtils } from '@hulu-react-style-components/util';
import merge from 'lodash.merge';

import * as Theme from './branches';
import { Colors } from './branches';

type KeyTypes<T> = {
  [K in keyof T]: T[K];
}[keyof T];
// from: https://archive.is/g7VRQ
type UnionToIntersection<T> = (T extends object ? (x: T) => object : never) extends (x: infer R) => object ? R : never;
type ThemeTypeAll = UnionToIntersection<KeyTypes<typeof Theme>>;
export const defaultMCTheme = Object.values(Theme).reduce((themeObject, value) => {
  return merge(themeObject, value);
}, {}) as ThemeTypeAll;

export type MCThemeType = Partial<ReturnType<typeof themeUtils.createTheme>> & Partial<typeof defaultMCTheme>;
const theme = themeUtils.createTheme(defaultMCTheme) as MCThemeType;

const { TrekTextField, TrekDropDown } = theme;

const mergeProps = {
  TrekTextField: {
    pallete: { ...TrekTextField?.pallete, borderColor: Colors.admanColors.gray.light.fg[5] },
    sizes: TrekTextField?.sizes,
  },
  TrekDropDown: {
    pallete: {
      ...TrekDropDown?.pallete,
      borderColor: Colors.admanColors.gray.light.fg[5],
      borderHover: Colors.admanColors.gray.light.fg[5],
    },
    sizes: TrekDropDown?.sizes,
  },
};

const expandTheme = (defaultTheme: MCThemeType, overwriteProps: Object): MCThemeType => {
  return merge(defaultTheme, overwriteProps);
};

export default expandTheme(theme, mergeProps);
