export const enum LoaderSize {
  medium = 'medium',
  small = 'small',
  big = 'big',
}

export const enum LoaderType {
  transparent = 'transparent',
  cover = 'cover',
}
