import { makeVar, ReactiveVar } from '@apollo/client';

const getCleanValueForStorage = (value: unknown): string => {
  return typeof value === 'string' ? value : JSON.stringify(value);
};

const makeVarPersisted = <T>(initialValue: T, storageName: string): ReactiveVar<T> => {
  let value = initialValue;

  const previousValue = localStorage.getItem(storageName);
  if (previousValue !== null) {
    try {
      value = JSON.parse(previousValue);
    } catch {
      // Values is not a JSON
      value = previousValue as unknown as T;
    }
  }

  const rv = makeVar<T>(value);

  // pass 'undefined' to remove the entry
  const onNextChange = (newValue: T | undefined): void => {
    try {
      if (newValue === undefined) {
        localStorage.removeItem(storageName);
      } else {
        localStorage.setItem(storageName, getCleanValueForStorage(newValue));
      }
    } catch {
      // ignore localStorage Error if any
    }

    rv.onNextChange(onNextChange);
  };

  rv.onNextChange(onNextChange);

  return rv;
};

export default makeVarPersisted;
