import React, { FC } from 'react';

import { LoaderWrap, Wrap } from './styled';
import { LoaderSize, LoaderType } from './types';

export type LoaderProps = {
  size?: LoaderSize;
  type?: LoaderType;
};

const Loader: FC<LoaderProps> = ({ size = '', type = '' }): JSX.Element => {
  return (
    <Wrap className={type} data-testid="loaderContainer">
      <LoaderWrap className={size} data-testid="loaderWrapper" />
    </Wrap>
  );
};

export default Loader;
