export type OpenGhostModeProps = {
  organizationId: string;
  organizationName: string;
  redirectUrl?: string;
  adAccountId?: string;
  userId?: string;
};

export type GhostMode = {
  isGhostMode: boolean;
  handleOpenGhostMode: (props: OpenGhostModeProps) => void;
  organizationName?: string;
  handleCloseGhostMode?: () => void;
};

export const defaultGhostModeValues: GhostMode = {
  isGhostMode: false,
  handleOpenGhostMode: () => void 0,
};
