export const allowedDomains = [
  '@hulu.com',
  '@disney.com',
  '@disneystreaming.com',
  '@danads.se',
  '@abc.com',
  '@espn.com',
  '@fxnetworks.com',
  '@natgeo.com',
  '@bamtech.com',
  '@freeform.com',
];
