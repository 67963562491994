import styled, { css } from 'styled-components';

export const TooltipContainer = styled.div(
  ({ theme: { admanColors, spacers } }) => css`
    .badgeTooltip {
      max-width: 500px;

      .ant-tooltip-inner {
        background-color: ${admanColors!.gray.dark.bg3[1]};
        padding: 6px ${spacers!.spacer01} 6px ${spacers!.spacer02};
      }

      .ant-tooltip-arrow::before {
        background-color: ${admanColors!.gray.dark.bg3[1]};
      }
    }
  `
);
