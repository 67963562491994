import styled from 'styled-components';

export const ErrorContainer = styled.div`
  background: ${({ theme }): string => theme.admanColors!.gray.light.bg1[0]};
  color: ${({ theme }): string => theme.admanColors!.gray.light.fg[1]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
`;

export const ErrorTitle = styled.h1`
  font-size: ${({ theme }): string => theme.spacers!.spacer05};
  margin-bottom: ${({ theme }): string => theme.spacers!.spacer04};
`;

export const ErrorMessage = styled.p`
  font-size: ${({ theme }): string => theme.spacers!.spacer04};
`;
