import { Button } from '@hulu/react-style-components';
import { ComponentType } from 'react';
import { FallbackProps } from 'react-error-boundary';

import { ErrorContainer, ErrorMessage, ErrorTitle } from './styled';

const GlobalErrorFallback: ComponentType<FallbackProps> = ({ error, resetErrorBoundary }): JSX.Element => (
  <ErrorContainer data-testid="globalErrorBoundary">
    <ErrorTitle>Application Error</ErrorTitle>
    <ErrorMessage>The application has stopped responding unexpectedly.</ErrorMessage>
    <ErrorMessage>Try to refresh the page by button below or contact application administrator.</ErrorMessage>
    <ErrorMessage data-testid="globalErrorBoundaryMessage">{error.message}</ErrorMessage>
    <Button onClick={resetErrorBoundary} data-testid="rerenderErrorBoundaryBtn" className="primary">
      Refresh
    </Button>
  </ErrorContainer>
);

export default GlobalErrorFallback;
